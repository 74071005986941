.work {
    height: auto;
    padding: 0 10vw;

    .item1 { grid-area: x1; }
    .item2 { grid-area: x2; }
    .item3 { grid-area: x3; }
    .item4 { grid-area: x4; }
    .item5 { grid-area: x5; }

    .item10 { grid-area: x10; }
    .item11 { grid-area: x11; }
    .item12 { grid-area: x12; }
    .item13 { grid-area: x13; }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'x1 x2'
            'x3 x4'
            'x5 x6';
        gap: 10px;
        padding: 20px;
        border-radius: 25px;
        
      


        .item1, .item2, .item3, .item5, .item6 {
            img {
                width: 100%; 
                max-height: 250px; 
                object-fit: cover;  
                border-radius: 25px;
            }
        }

        .item4 {
            img {
                width: calc(50% - 5px);
                max-height: 250px; 
                object-fit: cover; 
                border-radius: 25px;
                &:nth-child(1) {
                    padding-right: 10px;
                }
    
            }
        }
    }
    // mobible
    @media screen { 
        @media (max-width: 1200px ){
            .desk {
                display: none !important;
            }
            .column {
                padding: 2vw;
                border-radius: 25px;
                img {   
                    transition: opacity 2s ease-in-out;
                    width: 100%;
                    max-height: 113px;
                    object-fit: cover;
                    border-radius: 25px;
                }
                .item11 {
                    padding: 2vw 0;
                    gap: 2vw;
                    img { 
                        transition: opacity 2s ease-in-out;
                        width: 48%;
                    }
                }
            }
        }
        
    }

    @media screen { 
        @media (min-width: 1200px ){
            .mobile {
                display: none !important;                
            }
        }
        
    }
}


.item1 img, .item2 img, .item3 img, .item4 img, .item5 img, .item6 img {
    transition: opacity 2s ease-in-out;
}

.gallery-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
  }
  
  .full-width-image {
    width: 90%;
    max-height: 100%;
    object-fit: contain;
  }

.nextButton {
    cursor: pointer;

    @media screen { 
        @media (max-width: 1200px ){
            display: none;
        }

    }
}

.mobileButtons {
    @media screen { 
        @media (min-width: 1200px ){ 
            display: none !important;
        }
    }

    @media screen { 
        @media (max-width: 1200px ){
            position: absolute;
            display: flex;
            justify-content: space-between;
            padding: 2vw;
        }

    }
}