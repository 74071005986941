
.home {
    height: var(--page-height);
    color: white;
    overflow: hidden;
    background-image: linear-gradient(to right, var(--bg-color),  var(--bg-color-20)), url("https://www.ivyleaguepainting.com/imgs/Ivy-League-Painting-Home-Page-BackgroundImage.jpeg");
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;    

    display: flex;
    flex-direction: column;
    
    align-items: center;

    .row {
        gap: 1vw;     
        
    }

    .quoteButton {
        background: #fdc500 !important;

    }

    .spaced {
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        gap: 1.2vh; 
        padding-left: .66vw;
    }

    .footer {
        background-color: transparent;
        bottom: 0;
        position: absolute;
        width: 100%;
        text-align: right;  /* Align text to the right */
    }
}

@media (min-width: 1200px ){
    .home {
        justify-content: center;
       
    }
  }

@media (max-width: 1200px ){
    .home {
        background-image:url("https://www.ivyleaguepainting.com/imgs/Ivy-League-Painting-Home-Page-BackgroundImage.jpeg");
        
    }

   
  }