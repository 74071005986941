.form {
    // border: 2px solid var(--faded-white);
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px 0 var(--faded-grey);
    input, textarea {
        color: var(--white-text) !important;
        background-color: var(--faded-grey) !important;
        border: 1px solid transparent;
        margin-bottom: 1vh;
        border-radius: 10px;
        padding: 10px;
        width: 95%;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; 
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
        }
    }

    .radio-container { 
        display: flex;
        flex-direction: column;
        // font-size: 12px;
        input {
            margin: 0;
            margin-right: .5vw;
            margin-bottom: .5vh;
            width: auto;
        }
    }

    .checkbox {
        display: flex;
        flex-direction: column;
        // font-size: 12px;
        input {
            margin: 0;
            margin-right: .5vw;
            margin-bottom: .5vh;
            width: auto;
        }
    }

    

    @media (max-width: 1200px ){
            width: 98%;
      }

    .label-container {
        width: 100%;
        padding-bottom: 1vh;
    }

    .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.checkbox {
    display: flex;
    flex-direction: row !important;
}

.radio-tile {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    background-color: var(--faded-grey);
    color: var(--white-text);
    padding: 15px 25px; 
    margin: 5px; 
    border-radius: 10px; 
    cursor: pointer;
    border: 2px solid var(--faded-white); 
    transition: background-color 0.3s, transform 0.3s;
}

.radio-tile input[type="radio"] {
    display: none;
}

.radio-tile .radio-label {
    pointer-events: none; 
}

.radio-tile:hover {
    transform: scale(1.05);
}

.radio-tile input[type="radio"]:checked + .radio-label {
    color: var(--faded-grey); 
    transform: scale(0.95);

}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: transparent; /* Makes the icon invisible */
    background: transparent; /* Avoids any background changes on the icon */
    cursor: pointer; /* Shows a pointer cursor on hover to indicate this is clickable */
}


