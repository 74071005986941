.contact {
    height: auto;
    padding: var(--page-padding);
    padding-bottom: 5vh;

    a {
        color: var(--text-color);
        text-decoration: none;
    }

    @media screen { 
        @media (min-width: 1200px ){
            .mobile {
                display: none;
            }
        }
        
    }
    
    @media screen { 
        @media (max-width: 1200px ){
            .desk {
                display: none;
            }

            .ptb-3{
                padding: 1vh 0;
            }
            
        }
        
    }
}

.contactPic {
    height: 90%;
    width: 70%;
    object-fit: cover;
    
}