.about {
    height: auto;
    padding: var(--page-padding);
    @media screen { 
        @media (min-width: 1200px ){
            .mobile {
                display: none !important;
            }
        }
        
    }
    
    @media screen { 
        @media (max-width: 1200px ){
            .desk {
                display: none !important;
            }
            
        }
        
    }
    
}


@media screen { 
    @media (max-width: 600px ){

    }
    
}