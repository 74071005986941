.services {
    height: auto;
    // height: var(--page-height);
    padding: var(--page-padding);

    .card {
        padding: 10px;
        margin: 0 2vw;
        border-radius: 10px;
        padding: var(--card-padding);
    }

    img.service { 
        width: 100%; // Width relative to the container
        height: 200px; 
        object-fit: cover;
        border-radius: 10px;
    }

    .button {
        width: 100%;
    }

    @media screen { 
        @media (min-width: 1200px ){
            .mobile {
                display: none;
            }
        }
        
    }
    
    @media screen { 
        @media (max-width: 1200px ){
            .desk {
                display: none;
            }

            img.service { 
                width: 100%; // Width relative to the container
                height: 150px; 
                object-fit: cover;
                border-radius: 10px;
            }
            
        }
        
    }
}

