

.reivews {
    height: auto;
    padding-top: 10vh;
    @media screen { 
        @media (max-width: 800px ){
            padding-top: 15vh;
        }
    }

    .swipeIcon {
        display: none;
        @media screen { 
            @media (max-width: 800px ){
                display: block;
                position: absolute;
                right: 50vw;
                transform: translateY(120px);
                z-index: 100;
                height: 50px;
            }
        }
    }
  }