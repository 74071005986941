:root {
  --header-height: 10vh;
  --page-height: 100vh;
  --page-padding: 10vh 10vw 0 10vw;
  --card-padding: 15px;
  --white-text: rgba(255, 255, 255, .95);
  --card-color: #01112040;
  --faded-white: rgba(255, 255, 255, .5);
  --80-white: rgba(255, 255, 255, .8);
  --bg-color: #003566;
  --bg-color-dark: #001529;  
  --bg-color-dark-20: #00152980; 
  --bg-color-20: #00356620;
  --faded-grey: #FFFDFD20;
  --media-size: 1200px;

  @media screen { 
    @media (max-width: 800px ){
      --page-padding: 15vh 5vw 0vh 5vw;
    }
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  // background-color: var(--bg-color);
  background: linear-gradient(to bottom, var(--bg-color),var(--bg-color-dark));

  .large {
    font-size: 6vh;
  }

  .largest {
    font-size: 8vh;
    @media (max-width: 1200px ){
      font-size: 3.5vh;
    }
  }

  .clear {
    background-color: transparent !important;
  }

  .medium-large {
    font-size: 3vh;
    @media (max-width: 1200px ){
      font-size: 2vh;
    }
  } 

  .medium-small {
    font-size: 1.5vh;
    @media (max-width: 1200px ){
      font-size: 1.15vh;
    }
  }
  
  .small {
    font-size: 1vh;
  }

  .medium {
    font-size: 2vh;
  }

  .fadedGrey {
    background-color: var(--faded-grey) !important;
  }

  .white {
    color: var(--white-text);
  }

  .halfW {
    width: 50% !important; 
  }

  .textLeft {
    text-align: left;
  }

  .textCenter {
    text-align: center;
  }

  .w75 {
    width: 75% !important;
  }

  .fadedWhite {
    background-color: var(--faded-white) !important;
  }

  .fadedWhiteText {
    color: var(--faded-white) !important;
  }

  .cardBlue {
    background-color: var(--card-color);
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .m-1 {
    margin: 1vh;
  }

  .mlr-3 {
    margin-left: 3vh;
    margin-right: 3vh;
  }

  .mt-5 {
    margin-top: 5vh;
  }

  .pt-10 {
    padding-top: 10vh;
  }

  .p-1{
    padding: 1vh;
  }

  .p-5 {
    padding: 5vh;
  }


  .bottom10 {
    bottom: 10vh;
  }
  .p-15 {
    padding: 15vh;
  }

  .p-2 {
    padding: 2vh;
  }

  .pl-2 {
    padding-left: 2vh;
  }

  .pr-2 {
    padding-right: 2vh;
  }



  .pt-7 {
    padding-top: 7vh;
  }

  .pl-10 {
    padding-left: 10vw;
  }

  .pl-5 {
    padding-left: 5vw;
  }

  .pr-10 {
    padding-right: 10vw;
  }

  .ptb-half {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
  }

  .ptb-3 {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  .pt-1 {
    padding-top: 1vh;
  }

  .pb-1 {
    padding-bottom: 1vh;
  }

  .pb-3 {
    padding-bottom: 3vh;
  }

  .pt-3 {
    padding-top: 3vh;
  }

  .p b-3 {
    padding-bottom: 3vh;
  }

  .pl-1 {
    padding-left: 1vh;
  }

  .ptb-1 {
    padding: 1vh 0;
  }

  .plr-3 {
    padding-left: 3vh;
    padding-right: 3vh;
  }

  .plr-1 {
    padding: 0 1vh;
  }

  .br-10 {
    border-radius: 10px;
  }

  .pr-1 {
    padding-right: 1vh;
  }

  .pt-15 {
    padding-top: 13vh;
  }

  .textShadow {
    text-shadow: 1px 1px 2px #000000;
  }

  .textShadowWhite {
    text-shadow: 1px 1px 2px #ffffff;
  }

  .m-5 {
    margin: 5vh;
  }

  .br-25 {
    border-radius: 25px;
  }

  .mh-150 {
    max-height: 150px;
  }

  .mh-75 {
    max-height: 75px;
  }

  .m-6 {
    margin: 6vh;
  }

  .halfH {
    height: 50vh !important;
  }

  .fullWidth {
    width: 100% !important;
  }

  .fullH {
    height: 100% !important;
  }

  .circle {
    border-radius: 100% !important; 
  }

  .even50 {
    min-width: 50px;
    min-height: 50px;
  }

  .even20 {
    min-width: 20px;
    min-height: 20px;
  }

  .even40 {
    min-width: 40px;
    min-height: 40px;
  }

  .m-20 {
    margin: 20vh
  }

  .top {
    justify-content: flex-start;
    align-self: start;
  }

  .bottom {
    justify-content: flex-end;
    align-self: end;
    bottom: 0;
  }

  .row {
    display: flex;
    flex-direction: row;

    &.middle {
      align-items: center;
    }
  }

  .flex1 {
    flex: 1;
  }

  .end2end {
    justify-content: space-between;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  .titleText {
    font-size: 4vh;
    font-weight: bold;
  }

  .button {
    background-color: var(--white-text);
    padding: 15px;
    border-radius: 12px;
    border: 1px solid transparent;

    &:hover {
      background-color: lightgray;
    }
    &:active {
      background-color: gray;
      transform: scale(0.9);
    }
  }

  .red {
    color: red;
  }

  .black {
    color: black !important;
  }

  .min-quote {
    min-width: 15vw;
    padding-top: .75vw;
    padding-bottom: .75vw;
  }

  .quote-text {
    font-size: 2.4vh;
    font-weight: bold;
  }

  .min-row {
    min-width: 12vw;
    min-height: 11vh;
  }

  .button-text-lg {
    font-size: 2.8vh;
    font-weight: bold;
  }

  .button-text-sm {
    font-size: 1.2vh;
    font-weight:bold;
  }
  
  .font-left {
    text-align: left;
  }

  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
