.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: var(--header-height);
    width: 100%;
    background: linear-gradient(to bottom, var(--bg-color-dark), transparent);
    position: fixed;
    z-index: 1000;

    // &.past-home {
    //     animation: fadeIn 1.5s forwards; 
    // }

    // &:not(.past-home) {
    //     animation: fadeOut 1.5s forwards; 
    // }
    

    img {
        position: absolute;
        top: 1vh;
        left: .5vw;
        height: calc(var(--header-height) - 2vh); 
    }

    

    button {
        font-size: 2vh;
        background-color: transparent;
        border: none;
    }


    
}

@keyframes fadeIn {
    from {
        background: transparent;
    }
    to {
        background: linear-gradient(to bottom, var(--bg-color-dark), transparent);
    }
}

@keyframes fadeOut {
    from {
        background: linear-gradient(to bottom, var(--bg-color-dark), transparent);
    }
    to {
        background: transparent;
    }
}

@media screen { 
    @media (min-width: 1200px ){
        .header {
            justify-content: center;
        }
    }
    
}

@media screen { 
    @media (max-width: 1200px ){
        .header {
            height: calc(var(--header-height) - 3vh); 
            justify-content: center;
            img {
                height: calc(var(--header-height) - 4vh); 
                padding: 0;
                top: .5vh;
                left: 1vw;
            }
        }
    }
    
}

@media screen { 
    @media (max-width: 800px ){
    .header {
        height: auto;
        padding-top: 1vh;
        flex-direction: column;
        img {
            position: relative;
            max-height: 60px;
            padding: 0;
            top: 0;
            left: 0;
        }
        .link-container {
            padding-bottom: 3vh;
        }
    }
    
    }
    
}