.delta {
    height: auto;
    // height: var(--page-height);
    padding: var(--page-padding);

    .card {
        padding: 10px 30px;
        // border: 2px solid white;
        box-shadow: 0 0 10px 0px var(--faded-grey);
        border-radius: 25px;
        margin: 2vw 0;
        width: 100%;

    }

    .column {
        width: 50%;
    }

    img {
        height: 90%;
        width: 70%;
        min-height: 250px;
        object-fit: cover;
    }

    @media screen { 
        @media (max-width: 1200px ){
            .column {
                justify-content: space-between;
                width: 100% !important;
            }
            .desk {
                display: none !important;
            }
            .titleText {
                text-align: center;
                // font-size: 2vw;
            }
            .medMobileText {
                font-size: 1.5vh;
            }
            .halfW {
                width: 100% !important;
            }
            .mobileText {
                font-size: 3vh;
            }
         
        }
        
    }

    @media screen { 
        @media (min-width: 1200px ){
            .mobile {
                display: none !important;

                
            }

       
        }
    }
}