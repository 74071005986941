.slider-container {
    overflow: hidden;
    width: 100%;
    
    .even40:hover {
        cursor: pointer;
        &:active {
            transform: scale(0.9);
          }

        
    }

    .mobile .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;    
    }

    .mobileCard {
        margin: 0;
        width: 50vw;
        height: 50vw;
    }
}

.slider {
    display: flex;

    .titleText {
        padding-bottom: 5%;
    }
}

.sliderCard {
    margin-top: 25px;
    flex: 0 0 auto;
    width: 20vw; 
    height: 20vw;
    margin-right: 20px; 
    padding: 40px;
    background: royalblue;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    
    .row {
        height: 25%;
        align-items: center;
        
        img {
            
            width: 80px;
            height: 80px;
            border-radius: 100%;
        }
    }

    @media screen { 
        @media (max-width: 1200px ){
            width: 40vw; 
            height: 40vw;
            .titleText {
                font-size: 2vh
            }
            .medium {
                font-size: 1.5vh;
            }

            img {
            
                width: 25px !important;
                height: 25px  !important;
                border-radius: 100%;
            }
        }
        
    }
   
}

.stars {
    color: yellow;
    padding-bottom: 5%;
    font-size: 2vh;
}

.sliderText {
    min-height: 50%;
    overflow: hidden;
}

@media screen { 
    @media (min-width: 1200px ){
        .mobile {
            display: none !important;                
        }
    }
    @media (max-width: 1200px ){
        .buttonRow {
            display: none !important;
        }
        .slider {
            display: none;
        }
    }


}